<template>
    <div class="k-flex-col">
        <div class="k-flex-row">
            <div class="flex-grow-1 mr-3">
                <b-input-group>
                    <template #prepend>
                        <b-input-group-text>
                            <b-icon-search />
                        </b-input-group-text>
                    </template>
                    <b-form-input
                        placeholder="Rechercher un projet"
                        v-model="filter"
                        debounce="500"
                    />
                </b-input-group>
            </div>
            <div class="ml-3 mr-3">
                <div class="text-kalkin-1 k-text-sm mb-1">Mes Projets</div>
                <b-form-checkbox
                    class="ml-3"
                    v-model="myProjects"
                    switch
                    @change="handleMyProjects"
                />
            </div>
            <div class="d-flex flex-column">
                <small class="text-kalkin-1 text-center w-100 mb-1">
                    Projets par page
                </small>
                <b-form-group>
                    <b-form-radio-group
                        v-model="perPage"
                        size="sm"
                        button-variant="outline-kalkin-1"
                        buttons
                    >
                        <b-form-radio :value="5">5</b-form-radio>
                        <b-form-radio :value="10">10</b-form-radio>
                        <b-form-radio :value="20">20</b-form-radio>
                        <b-form-radio :value="50">50</b-form-radio>
                        <b-form-radio :value="100">100</b-form-radio>
                    </b-form-radio-group>
                </b-form-group>
            </div>
        </div>
        <b-table
            class="text-break mt-2"
            :items="dataProvider"
            :fields="fields"
            :current-page="currentPage"
            :filter="filter"
            :per-page="perPage"
            sort-by="createdAt"
            :sort-desc="true"
            show-empty
            empty-text="Aucun projet"
            empty-filtered-text="Aucun projet trouvé"
            stacked="md"
            hover
            thead-class="text-center text-nowrap"
            tbody-tr-class="pointer"
            @row-clicked="goProject"
            striped
            ref="table"
        >
            <template #table-busy>
                <div class="text-center my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong class="ml-2">Chargement des données...</strong>
                </div>
            </template>
            <template #cell(createdAt)="data">
                {{ data.item.createdAt | dateFromISO(true) }}
            </template>
        </b-table>
        <div>
            <b-pagination
                class="m-auto"
                v-model="currentPage"
                :per-page="perPage"
                :total-rows="totalRows"
                align="center"
                size="sm"
                first-number
                last-number
            />
        </div>
    </div>
</template>

<script>
export default {
    data: function() {
        return {
            myProjects: false,
            totalRows: 0,
            currentPage: 1,
            perPage: 10,
            fields: [
                {
                    key: "name",
                    label: "Nom",
                    sortable: true,
                    tdClass: "text-kalkin-1 font-weight-bold",
                },
                {
                    key: "description",
                    label: "Description",
                },
                {
                    key: "createdAt",
                    label: "Création",
                    sortable: true,
                    tdClass: "text-nowrap text-center px-4",
                },
            ],
            filter: "",
        };
    },

    watch: {
        async filter() {
            await this.fetchTotalRows();
        },
    },

    methods: {
        async fetchTotalRows() {
            this.totalRows = await this.$store.dispatch(
                "project/countFSPProjects",
                {
                    filter: `name substring ${this.filter} or description substring ${this.filter}`,
                },
            );
        },

        async dataProvider(ctx) {
            let projects = [];
            if (this.myProjects) {
                projects = await this.$store.dispatch("project/getMyProjects", {
                    filter: `name substring ${this.filter} or description substring ${this.filter}`,
                    sortby: (ctx.sortDesc ? "-" : "") + ctx.sortBy,
                    limit: this.perPage,
                    offset: this.perPage * (this.currentPage - 1),
                });
            } else {
                projects = await this.$store.dispatch(
                    "project/getFSPProjects",
                    {
                        filter: `name substring ${this.filter} or description substring ${this.filter}`,
                        sortby: (ctx.sortDesc ? "-" : "") + ctx.sortBy,
                        limit: this.perPage,
                        offset: this.perPage * (this.currentPage - 1),
                    },
                );
            }
            return projects;
        },

        handleMyProjects() {
            this.refreshTable();
        },

        refreshTable() {
            if (this.$refs.table) {
                this.$refs.table.refresh();
            }
        },

        goProject(item) {
            this.$router.push({
                name: "AdminProject",
                params: { projectId: item.id },
            });
        },
    },

    async beforeMount() {
        await this.fetchTotalRows();
    },
};
</script>
