<template>
    <div class="k-page-flex">
        <div class="k-page-title">
            <h1>Projets</h1>
            <b-button
                size="sm"
                variant="outline-kalkin-2"
                @click="goCreateProject"
            >
                <b-icon-folder-plus /> Créer un nouveau projet
            </b-button>
        </div>

        <div class="k-page-body">
            <TableProjects />
        </div>
    </div>
</template>
<script>
import TableProjects from "@/components/model/project/TableProjects";

export default {
    components: {
        TableProjects,
    },

    methods: {
        goCreateProject: function() {
            this.$router.push({ name: "AdminCreateProject" });
        },
    },
};
</script>
